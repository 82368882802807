import Rellax from 'rellax';

function rellaxActionFast() {
  if (matchMedia('(min-width:768px)').matches) {
    const rellax = new Rellax('.js-rellax-fast', {
      speed: 3,
      center: true,
      wrapper: null,
      round: true,
    });
  }
}

function rellaxActionSlow() {
  if (matchMedia('(min-width:768px)').matches) {
    const rellax = new Rellax('.js-rellax-slow', {
      speed: 1,
      center: true,
      wrapper: null,
      round: true,
    });
  }
}

rellaxActionFast();
rellaxActionSlow();
